/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
import { SHOW_STATION_ALL, ADD_STATION, SORT_STATION, UPDATE_STATION, REMOVE_STATION, UPDATE_SEARCH_TERM } from '../actions/typ';

const initialState = {
  data: [],
  searchTerm: '',
  filteredData: [],
  sortDirection: 'asc',
  sortColumn: 'nazwa_stacji',
};

export default function stationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_STATION_ALL:
      const sortedDataCopy = payload
        .map((item) => ({ ...item }))
        .sort((a, b) => {
          // Zakładam, że sortowanie jest po nazwie stacji
          const valueA = a.nazwa_stacji.toLowerCase();
          const valueB = b.nazwa_stacji.toLowerCase();
          return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
        });

      return {
        ...state,
        data: sortedDataCopy,
        filteredData: sortedDataCopy,
      };
    case ADD_STATION:
      return {
        ...state,
        data: [...state.data, payload.data],
        filteredData: [...state.filteredData, payload.data],
      };
    case UPDATE_STATION:
      const updatedData = state.data.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
      const updatedFilteredData = state.filteredData.map((item) => {
        if (item.id === payload.data.id) {
          return payload.data;
        }
        return item;
      });
      return {
        ...state,
        data: updatedData,
        filteredData: updatedFilteredData,
      };
    case REMOVE_STATION:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload.id),
        filteredData: state.filteredData.filter((item) => item.id !== payload.id),
      };
    case UPDATE_SEARCH_TERM:
      const filteredData = state.data.filter((item) =>
        Object.values(item).some((value) => {
          if (value && typeof value === 'string') {
            return value.toLowerCase().includes(payload.toLowerCase());
          }
          return false;
        }));
      return {
        ...state,
        searchTerm: payload.term,
        filteredData, // Zaktualizowane pole filteredData
      };
    case SORT_STATION:
      const { column, direction } = payload;
      const sortedData = [...state.filteredData].sort((a, b) => {
        const valueA = typeof a[column] === 'string' ? a[column].toLowerCase() : String(a[column]).toLowerCase();
        const valueB = typeof b[column] === 'string' ? b[column].toLowerCase() : String(b[column]).toLowerCase();

        if (direction === 'asc') {
          return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
        }

        return valueB.localeCompare(valueA, undefined, { sensitivity: 'base' });
      });
      return {
        ...state,
        filteredData: sortedData,
        sortColumn: column,
        sortDirection: direction,
      };
    default:
      return state;
  }
}
