import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU, SHOW_DIS_FAIL } from '../actions/typ';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const Main = styled('main')(({ theme }) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  marginTop: 90,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('lg')]: {
    marginLeft: 270,
    width: 'calc(100% - 280px)',
  },
  [theme.breakpoints.down('lg')]: {
    marginLeft: '20px',
    width: 'calc(100% - 20px)',
    padding: '16px',
  },
}));

const MainTemplate = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch({ type: SHOW_DIS_FAIL });
  }, [location]);
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const leftDrawerOpened = useSelector((state) => state.customizationReducer.opened);
  const handleDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const { user: currentUser } = useSelector((state) => state.auth);

  /*   if (!currentUser) {
    return <Navigate to="/login" />;
  } */

  return (
    <>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <Sidebar theme={theme} drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleDrawerToggle} />
      <Main theme={theme}>
        <Outlet />
      </Main>
    </>
  );
};
export default MainTemplate;
