import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { BrowserView, isMobileOnly, TabletView } from 'react-device-detect';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import NavigationScroll from './components/NavigationScroll';
import MobileTemplate from './templates/MobileTemplate';
import themes from './themes';

import Router from './routes';
import { logout } from './actions/auth';

const App = () => {
  const navigate = useNavigate();

  const { user: currentUser } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!currentUser) {
      return navigate('/login');
    }
  }, [currentUser]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes()}>
        <CssBaseline />
        <NavigationScroll>
          <BrowserView>
            <Router />
          </BrowserView>
          <TabletView>
            <Router />
          </TabletView>
        </NavigationScroll>
        {isMobileOnly && <MobileTemplate />}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
