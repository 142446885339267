import React, { useState } from 'react';
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { IconSearch } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import { updateSearchTerm } from '../../actions/station';

const Search = () => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    dispatch(updateSearchTerm(term));
  };
  return (
    <FormControl sx={{ width: { xs: '50%', md: 304 } }}>
      <OutlinedInput
        size="small"
        id="header-search"
        startAdornment={(
          <InputAdornment position="start" sx={{ mr: 0.5 }}>
            <IconSearch />
          </InputAdornment>
        )}
        aria-describedby="header-search-text"
        inputProps={{
          'aria-label': 'weight',
        }}
        placeholder="Szukaj"
        value={searchTerm}
        onChange={handleSearch}
      />
    </FormControl>
  );
};
export default Search;
