import React, { useCallback } from 'react';
import { Box, Drawer, useMediaQuery, Typography, Grid, Button } from '@mui/material';
import { IconLogin } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';

import MenuList from './MenuList';
import { logout } from '../../actions/auth';

const Sidebar = ({ theme, drawerOpen, drawerToggle }) => {
  const matchUpMd = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
      <Drawer
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: 260,
            background: theme.palette.text.primary.dark,
            color: theme.palette.text.primary.dark,
            borderRight: 'none',
            [theme.breakpoints.up('lg')]: {
              top: '78px',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        color="inherit"
      >
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>Logo</Box>
        </Box>
        <MenuList />
        <Box container sx={{ display: 'flex', mt: 2 }} justifyContent="center;" alignItems="center">
          <Button variant="contained" endIcon={<IconLogin />} onClick={logOut}>
            Wyloguj
          </Button>
        </Box>
        <Box sx={{ position: 'absolute', bottom: 100, left: 0, right: 0, textAlign: 'center', p: 2 }}>
          <Typography align="center" variant="h4" sx={{ mb: 3 }} color="textSecondary">
            Wersja
          </Typography>
          <Typography align="center" variant="h6" color="textSecondary">
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
